import { InjectionToken } from '@angular/core';
import {
  provideUiReadyNotifier,
  UiReadyNotifier
} from '@bcf-vanilla-ts-v1-platforms/platform-worker/browser/ui-ready-notifier';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const VANILLA_TS_UI_READY_NOTIFIER: InjectionToken<UiReadyNotifier> = new InjectionToken<UiReadyNotifier>(
  'vanillaTsUiReadyNotifier',
  {
    factory: () => provideUiReadyNotifier()
  }
);
