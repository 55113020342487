import { ProductType } from '@bcf-shared-settings/settings/enums';
import { environmentCommon } from 'configs/environments/environment.common';

// eslint-disable-next-line @typescript-eslint/typedef
export const environmentBase = {
  ...environmentCommon,
  productType: ProductType.casino,
  brandI18Name: 'lvbetlv',
  brandCmsName: 'lvbetlv',
  brandName: 'LV BET',
  langs: ['en', 'ru', 'lv'],
  langsI18nMap: {
    en: 'en_US'
  },
  sitemapLangsMaps: {
    en: 'be'
  },
  cookiesFromUrlMap: {
    btag: 'lvbetlv_btag'
  },
  cmsDevicePrefix: false,
  cmsBrandNamePrefix: false,
  cmsHeadlessEnabled: true,
  ssrDefaultCacheTime: 5 * 60,
  staticImagesUrl: 'https://static-assets.lvbet.lv/images/'
};
