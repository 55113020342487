import { InjectionToken } from '@angular/core';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const PIQ_THEME: InjectionToken<Record<string, any>> = new InjectionToken<Record<string, any>>('PIQ_THEME', {
  factory: () => ({})
});

// eslint-disable-next-line @typescript-eslint/naming-convention
export const PIQ_CSS: InjectionToken<string> = new InjectionToken<string>('PIQ_CSS', {
  factory: () => ''
});

// eslint-disable-next-line @typescript-eslint/naming-convention
export const PIQ_THEME_ALTERNATIVE: InjectionToken<Record<string, any>> = new InjectionToken<Record<string, any>>(
  'PIQ_THEME_ALTERNATIVE',
  {
    factory: () => ({})
  }
);

// eslint-disable-next-line @typescript-eslint/naming-convention
export const PIQ_CSS_ALTERNATIVE: InjectionToken<string> = new InjectionToken<string>('PIQ_CSS_ALTERNATIVE', {
  factory: () => ''
});

// eslint-disable-next-line @typescript-eslint/naming-convention
export const PIQ_THEME_GAME: InjectionToken<Record<string, any>> = new InjectionToken<Record<string, any>>(
  'PIQ_THEME_GAME',
  {
    factory: () => ({})
  }
);

// eslint-disable-next-line @typescript-eslint/naming-convention
export const PIQ_CSS_GAME: InjectionToken<string> = new InjectionToken<string>('PIQ_CSS_GAME', {
  factory: () => ''
});
