import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@bcf-v2-platforms/platform-apis/window-provider';
import { WorkerReady } from '@bcf-v2-platforms/platform-worker/browser';
import { browserIdle } from '@bcf-v2-shared/misc/rx-operators/browser-idle';
import { Observable, first, map, shareReplay, zip } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AppLoadedService {
  private _readyShareRef$!: Observable<void>;

  constructor(
    private _workerReady: WorkerReady,
    @Inject(WINDOW) private _window: Window
  ) {}

  public init(): void {
    this.doOnReady().subscribe(() => {
      this._window.dispatchEvent(new Event('BcfAppLoaded'));
    });
  }

  public doOnReady(): Observable<void> {
    return (this._readyShareRef$ ??= zip([this._workerReady.isReady$, browserIdle()]).pipe(
      first(),
      map(() => undefined),
      shareReplay(1)
    ));
  }
}
