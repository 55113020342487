import { LadeskChatConfig } from '../types';

export const ladeskChatConfig: LadeskChatConfig = {
  perLangConfig: {
    en: {
      chatId: '7h22uog9',
      chatIdForLoggedInUser: 's9gvd364'
    },
    lv: {
      chatId: '1ke8ut2d',
      chatIdForLoggedInUser: '36kix660'
    },
    ru: {
      chatId: 'uwr05l0f',
      chatIdForLoggedInUser: 'hw64g2bs'
    }
  },
  scriptUrl: 'https://lvbetlv.ladesk.com/scripts/track.js',
  extraUserDetailsFields: { DOB: 'birthDate' }
};
