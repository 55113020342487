import { Theme } from 'paymentiq-cashier-bootstrapper';

export const piqTheme: Theme = {
  cashierbackground: {
    color: '#1A1A1E'
  },
  background: {
    color: '#202122'
  },
  inputbackground: {
    color: '#333638'
  },
  input: {
    color: '#A2A6A9',
    height: '64px',
    borderRadius: '10px'
  },
  headings: {
    color: '#fff'
  },
  labels: {
    color: '#76767F'
  }
};
