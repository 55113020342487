import { DiToken, registerDiToken } from '@bcf-vanilla-ts-v1-shared/di/di-token';

export type EnvCp = {
  userApiUrl: string;
  walletApiUrl: string;
  paymentApiUrl: string;
};

export type CpProductType = 'casino' | 'sportsbook';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const ENV_CP: DiToken<EnvCp> = new DiToken<EnvCp>('EnvCp');

// eslint-disable-next-line @typescript-eslint/naming-convention
export const CP_PRODUCT_TYPE: DiToken<CpProductType> = new DiToken<CpProductType>('CpProductType');

export function registerEnvCp(envCp: EnvCp): void {
  registerDiToken(ENV_CP, () => envCp);
}

export function registerCpProductType(productType: CpProductType): void {
  registerDiToken(CP_PRODUCT_TYPE, () => productType);
}
