import { Observable, Observer } from 'rxjs';

export function browserIdle(): Observable<void> {
  const windowAsAny: any = window;
  return new Observable<void>((observer: Observer<void>) => {
    const handle: any = windowAsAny.requestIdleCallback(() => {
      observer.next(undefined);
      observer.complete();
    });
    return (): void => windowAsAny.cancelIdleCallback(handle);
  });
}
