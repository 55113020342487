import { provideSingleton } from '@bcf-vanilla-ts-v1-shared/di/provide-singleton';
import { ReplaySubject, Subject } from 'rxjs';

export class UiReadyNotifier {
  public startIdUpdate$: Subject<string> = new Subject<string>();
  public finishIdUpdate$: Subject<string> = new Subject<string>();
  public isAnySetup$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  public start(id: string): void {
    this.isAnySetup$.next(true);
    this.startIdUpdate$.next(id);
  }

  public finish(id: string): void {
    this.finishIdUpdate$.next(id);
  }
}

export function provideUiReadyNotifier(): UiReadyNotifier {
  return provideSingleton(UiReadyNotifier, () => new UiReadyNotifier());
}
